// --------------------- Modal reset: --------------------- //

.modal-fullscreen {
	background-color: transparent;
	box-shadow: none;
	border: 0;
}

.modal-header {
	padding: 0;
	border: 0;
}

// --------------------- Search: --------------------- //

.modal--search {
	background-color: rgba(black, 0.5);
	.modal-dialog {
		@include media-breakpoint-up(sm) {
			margin: 0.5em;
			max-width: none;
		}
	}
	.modal-body {
		height: 50vh;
		@extend .l-flex--column;
		@extend .l-justify-around;
		@extend .l-align-center;
	}
	.modal-header {
		width: 100%;
		padding: {
			top: 0.5em;
			right: 0.5em;
		}
	}
	.search__input {
		background-color: transparent;
		border-color: transparent;
		border-bottom-color: white;
		color: white;
		padding: {
			top: 0.75em;
			right: 0.75em;
			bottom: 0;
			left: 0;
		}
		font-size: 1.25rem;
		width: auto;
		height: 2.5em;
		z-index: 1;
		@include media-breakpoint-down(sm) {
			max-width: 90%;
		}
		&:focus {
			z-index: 2;
			outline: transparent !important;
			border-color: transparent !important;
			border-bottom-color: $accent !important;
			font-size: 1.25rem !important;
		}
	}
	.search__button {
		background-color: transparent;
		min-width: 0;
		padding: {
			top: 0.5em;
			bottom: 0;
			left: 0;
		}
		transform: none !important;
		&:hover {
			background-color: transparent !important;
			&::before {
				content: none !important;
			}
		}
	}
	.isvg-search {
		transform: scale(1.25);
	}
	.close-button {
		color: white;
	}

	::-webkit-input-placeholder {
		color: $light;
		font-size: 1.25rem;
	}
	::-moz-placeholder {
		color: $light;
		font-size: 1.25rem;
	}
	:-ms-input-placeholder {
		color: $light;
		font-size: 1.25rem;
	}
}

.modal-content {
  overflow: auto;
  padding-bottom: 0.25em;
  @supports(display: grid) {
    padding-bottom: 0;
  }
}

// --------------------- Report an emergency: --------------------- //
.modal--cta {
	.modal-dialog {
    background-color: $warning;
    margin: 0.5em;
    padding: $spacer;
		font-size: calc(rem(24px) + 1vw);
		@include media-breakpoint-up(sm) {
			@include drop-shadow(tall, 1.0);
			max-width: 80vw;
			max-height: 80vh;
			padding: $spacer * 1.5;
			margin: 5% auto;
		}
		@include media-breakpoint-up(md) {
      max-width: 50vw;
      padding: $spacer * 2;
		}
	}
	.modal-content {
		background-color: transparent;
		box-shadow: none;
		border: 0;
		font-family: $font-family-headings;
		@include media-breakpoint-up(sm) {
			display: flex;
			flex-direction: column;
			// height: 25vh;
			justify-content: space-between;
		}
	}
	.btn {
		color: white;
		padding-bottom: 0.75rem;
	}
	.l-flex {
		margin-bottom: $spacer;
	}
	.l-flex:last-child {
		justify-content: center;
		@include media-breakpoint-up(lg) {
			justify-content: flex-end;
			padding-right: 0.25rem;
		}
	}
	.l-flex-unit {
		margin-left: 0.75rem;
	}
	.l-col-sm {
		margin-top: 0.25em;
		text-align: right;
	}
	h2 {
		font-size: rem(24px);
		@include media-breakpoint-only(sm) {
			padding-left: 5vw;
			padding-top: $spacer / 2;
		}
	}
	h3 {
		margin-bottom: 0;
	}
	.isvg-close--yellow {
		margin-bottom: 0.5em;
	}
	.heading--book {
		font-size: rem(24px);
	}
	a {
		color: black;
		&:active,
		&:focus {
			color: $secondary;
		}
	}
}


@include media-breakpoint-down(lg) {
	.modal-dialog {
		font-size: 150%;
		.isvg-bolt--red::before,
		.isvg-flame--blue::before {
			height: 42px;
		}
		.isvg-bolt--red::before {
			width: 42px;
		}
		.isvg-flame--blue::before {
			width: 32px;
		}
	}
}
.btn {
	@include button;
	&:hover {
		background-color: inherit;
	}
	padding: {
		top: $spacer;
		right: $spacer * 1.5;
		bottom: $spacer;
		left: $spacer * 1.5;
	}
	@include media-breakpoint-up(lg) {
		padding: {
			top: $spacer / 1.75;
			bottom: $spacer / 1.75;
		}
	}
	@include media-breakpoint-down(xs) {
		display: block;
		margin-bottom: $spacer;
	}
}

.btn-block,
.btn-container {
	display: block;
	margin: {
		top: $spacer;
		bottom: $spacer;
	}
}

.btn-ghost {
	@include button-ghost($black);
	padding-top: $spacer * 0.75;
	padding-bottom: $spacer * 0.75;
	@include media-breakpoint-up(lg) {
		padding: {
			top: $spacer / 1.75;
			bottom: $spacer / 1.75;
		}
	}
	&:hover {
		@include button-ghost($accent);
	}
}

.btn-trigon--black {
	@include button-ghost($black);
	position: relative;
	padding: {
		top: 0.5em;
		right: 2em;
		bottom: 0.5em;
		left: 2em;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		border: {
			top: solid 0.5em $black;
			right: solid 0.5em $black;
			bottom: solid 0.5em transparent;
			left: solid 0.5em transparent;
		}
	}
	&:hover {
		// color: $accent;
		border-color: $accent;
		&::after {
			border-top-color: $accent;
			border-right-color: $accent;
		}
	}
}

.btn-cutout {
	position: relative;
	z-index: 1;
	border: none;
	color: white;
	background: {
		color: transparent;
		size: cover;
		position: top right;
		repeat: no-repeat;
		image: url('/static/images/button_bg-black.svg'); // Default
	}
	overflow: hidden;
	padding-right: $spacer * 1.625;
	&:hover {
		color: white;
	}
	@include button-material-hover;
}

.btn-w-icon {
	display: inline-block;
	padding: {
		top: 0.5rem;
		right: 2rem;
		bottom: 0.5rem;
		left: 1rem;
	}
	line-height: 1.6;
	margin-bottom: 1em;
	.im, .isvg::before {
		position: relative;
		top: 5px;
		margin-right: 0.325rem;
	}
	.action-bar--desktop & {
		@extend .l-flex;
		@extend .l-align-center;
		@extend .l-justify-center;
		margin-bottom: 0;
		.im, .isvg::before {
			top: 0;
			margin-right: 0.5rem;
		}
	}
}

.btn-w-icon--oversized {
	@extend .btn-w-icon;
	@include media-breakpoint-up(sm) {
		padding-left: 3.5em;
		.isvg::before {
			position: absolute;
			left: 0.5em;
		}
	}
}

.btn-primary--black {
	@include heading;
	font-size: 1rem;
	padding: {
		top: 1rem;
		bottom: 0.75rem;
		left: 3rem;
		right: 3rem;
	}
	box-shadow:  0 6px 12px -8px rgba(black, 0.75);
	&:hover {
		box-shadow: 0 calc(6px + 0.125rem) 16px -4px rgba(black, 0.5);
	}
}

.btn-primary {
	@include heading;
	background-image: url('/static/images/button_bg-red--big.svg');
	font-size: 1.25rem;
	padding: {
		top: 0.5rem;
		bottom: 0.5rem;
		left: 2rem;
		right: 2rem;
	}
	box-shadow:  0 6px 12px -8px rgba(black, 0.75);
	&:hover {
		box-shadow: 0 calc(6px + 0.125rem) 16px -4px rgba(black, 0.5);
	}
}

.btn-red {
	background-image: url('/static/images/button_bg-red.svg');
}

.btn-black {
	background-image: url('/static/images/button_bg-black.svg');
}

.btn-cta {
	background-image: url('/static/images/button_bg-orange.svg');
	color: black;
	&:hover {
		color: black;
	}
}

.btn-input {
	@include button;
	@include button-material-hover;
	padding: 0.5em 1em;
}

.not-a-button {
	-webkit-appearance: none;
	cursor: pointer;
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	display: inherit;
	//&:focus {
	//	box-shadow: none;
	//	outline: 0;
	//}
}

.close-button {
	cursor: pointer;
	display: inline-block;
	color: inherit;
	@include media-breakpoint-down(md) {
		font: {
			size: rem(10px);
			family: $font-family-headings;
			weight: 400;
		}
		letter-spacing: 0.5px;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	span {
		display: block;
	}
}

.lighter-focus-indicator {
	&:focus {
		box-shadow: 0 0 0 0.2rem #ffffff87;
	}
}

.red-focus-indicator {
	&:focus {
		outline: solid 2px $accent;
	}
}

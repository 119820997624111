.support-bar {
	text-align: center;

	div[class^="col"] {
		@include media-breakpoint-down(md) {
			margin: {
				bottom: $spacer * 2;
			}
		}
	}
	.search__container {
		max-width: 90vw;
		margin: {
			left: auto;
			right: auto;
		}
		@include media-breakpoint-up(md) {
			max-width: 340px;
		}
	}
}

.site-footer {
	color: $lighter;
	font-size: rem(14px);
	@include media-breakpoint-up(xl) {
		font-size: 1rem;
		align-items: flex-end;
	}
	a {
		color: $white;
		@include media-breakpoint-up(md) {
			text-decoration: none;
		}
	}
	@include media-breakpoint-down(md) {
		text-align: center;
		padding: {
			left: $spacer;
			right: $spacer;
		}
		&.spacing-md__internal--vertical {
			padding-top: 0.5rem;
		}
	}
}

.copyright {
  img {
  	margin-right: $spacer / 2;
  }
	@include media-breakpoint-up(lg) {
		margin-bottom: $spacer * 2;
	}
}

.contact-links {
	a:first-child {
		text-decoration: underline;
	}
}

.footer-nav {
	justify-content: center;
	@include media-breakpoint-down(md) {
		margin: {
			top: $spacer;
			bottom: $spacer * 1.5;
		}
	}
}

.social-buttons {
	display: flex;
	justify-content: center;
	strong {
		line-height: 1.3;
		margin-right: $spacer/2;
	}
	.nav {
		margin-bottom: 0;
		margin-left: 1em;
		a,
		.isvg {
			display: block;
			width: 32px;
			height: 32px;
			&::before {
				width: 32px;
				height: 32px;
			}
		}
		a {
			opacity: 0.75;
			&:hover {
				opacity: 1.0;
			}
		}
	}
	.nav-link {
		padding: {
			left: 0.5rem;
			right: 0.5rem;
		}
	}
}
/* --------------------- Bootstrap-select --------------------- */
.bootstrap-select {
	@extend select;
	padding-right: 0.5em;

	>.dropdown-toggle.bs-placeholder {
		color: $black;
		background-color: transparent;
		&:hover {
			color: $black;
			transform: none;
		}
	}

	.dropdown-item:hover,
	.dropdown-item:focus {
		background-color: $lightest;
	}
	.dropdown-item:active {
		color: $black;
	}

	.dropdown-toggle[aria-expanded="true"] {
		background-color: transparent;
		border: 0;
		.filter-option-inner-inner {
			color: $black;
		}
	}
	.dropdown-toggle .filter-option {
		width: calc(100% - 24px);
	}
}

.article-blocks__heading {
	select,
	.bootstrap-select {
		padding: {
			top: 0.25em;
			bottom: 0.25em;
		}
	}
}

.btn.dropdown-toggle {
	@extend .not-a-button;
	margin-top: 2px;
	font-weight: normal;
	box-shadow: none;
	color: $black;

	&:hover {
		transform: none;
	}
	&:active {
		outline: 0;
	}
	&::after {
		content: none;
	}
}
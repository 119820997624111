.reverse {
	color: $white;
	background-color: $dark;

	-moz-::selection,
	::selection {
		background-color: $gray-900;
	}
	//.btn {
	//	@include drop-shadow(base, 1);
	//	&:hover {
	//		@include drop-shadow(tall, 0.8);
	//	}
	//}
}

.reverse--gray {
	background-color: $midamerican-medgray;
}

.reverse--stylish {
	@include stylish-background;
}

.bg-trigon {
	background: {
		image: url('/static/images/backgrounds/bg-trigon.svg');
		size: contain;
		position: bottom left;
		repeat: no-repeat;
	}
	@include media-breakpoint-down(md) {
		.btn-ghost {
			background-color: white;
		}
	}
}

.well {
	padding: $spacer $spacer * 1.5;
	margin: $spacer 0;
	color: $dark;
	background-color: $lightest;
	font-size: 75%;
}

.boxed {
	border: solid 1px $primary;
	padding: $spacer;
}

.line-separated:not(:last-child) {
	border-bottom: solid 1px $light;
	padding-bottom: 1em;
	margin-bottom: 1em;
}

.border-top--thin {
	border-top: solid 1px $light;
}

.dotted-border {
	// Use border-SIDE-width to display.
	// Ex. "border-bottom-width: 2px;"
	 border: {
  	style: dotted;
  	color: $primary;
  	image-source: url('/static/images/backgrounds/dotted-border-9_slice.svg');
  	image-slice: 33% 33%;
  	image-repeat: round;
  }
}

.dotted-border--small {
	border: {
		style: dotted;
		color: $lighter;
		width: 2px 0 0;
	}
}

.dotted-border--underline {
	@extend .dotted-border;
	flex: 1;
	display: inline-block;
	border-width: 0 0 6px 0;
	margin: {
		bottom: 4px;
		left: 4px;
		right: 4px;
	}
}
.dotted-border--underline-large {
	border-bottom-width: 8px;
	margin: {
		top: 4px;
		left: 8px;
		right: 8px;
	}
}

// .border-top {
	// border-top-width: 7px;
	// border-bottom-width: 0;
	// border-left-width: 0;
	// border-right-width: 0;
// }

.rule-separated {
	> a:not(:last-child),
	> div:not(:last-child),
	> p:not(:last-child),
	> span:not(:last-child),
	> li:not(:last-child) {
		display: inline-block;
		border-right: solid 1px $light;
		margin-right: 0.5rem;
		padding-right: 0.5rem;
		@include media-breakpoint-down(sm) {
			border-right: 0;
			display: block;
		}
	}
}

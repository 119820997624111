.feature-image {
	position: relative;
	width: 100%;
	min-height: 320px;
	background: {
		color: white;
		image: url('/static/images/content/electric-car-header.jpg');
		repeat: no-repeat;
		size: cover;
	}
	@include media-breakpoint-up(lg) {
		min-height: 540px;
	}

	&__headline {
		position: absolute;
		left: $spacer;
		z-index: 3;
		bottom: $spacer;
		@include media-breakpoint-up(md) {
			left: $spacer * 1.5;
			bottom: $spacer * 1.5;
		}
		@include media-breakpoint-up(xl) {
			left: $spacer * 3;
			bottom: $spacer * 3;
		}
	}

	&-lower::before {
		content: "";
		position: absolute;
		left: 0;
		z-index: 2;
		display: flex;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 0.8) 30%, rgba(white, 0) 50% );

		@include media-breakpoint-up(md) {
			background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 0.8) 40%, rgba(white, 0) 60% );
		}

		@include media-breakpoint-up(lg) {
			background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 0.8) 30%, rgba(white, 0) 50% ),
												linear-gradient(180deg, rgba(white, 1.0), rgba(white, 0.8) 20%, rgba(white, 0) 40% );
		}
	}

	&-upper::before {
		@include media-breakpoint-up(lg) {
			content: "";
			position: absolute;
			left: 0;
			z-index: 2;
			display: -webkit-box;
			display: -webkit-flex;
			display: flex;
			height: 100%;
			width: 100%;
			background-image: linear-gradient(180deg, rgba(white, 1.0), rgba(white, 0.8) 20%, rgba(white, 0) 40% );
		}
	}
}

.share-buttons__container {
	@extend .l-flex;
	@extend .l-justify-around;
	@extend .l-align-center;
	transform-origin: 100% 1.5em;
	transform: translateX(-165px) translateY(42px);
	position:absolute;
  left:0;
  right:0;
  height: 0;
  width: 215px;
  margin:auto;
  background-color: #d0d0d0;
  padding:0;
  overflow: hidden;
  opacity: 0;
  @include drop-shadow(shallow, 0);
  .isvg {
  	margin-right: 0;
  	display: block;
  }
}
.share-button:checked ~ .share-buttons__container {
	opacity:1;
	transform: translateX(-165px) translateY(48px);
	transition: box-shadow 0.5s, transform 0.5s;
	height: auto;
  // width: 215px;
  line-height: 0.825;
  padding: $spacer / 2;
  overflow: visible;

  &::before {
  	content: "";
  	display: block;
  	right: 0;
  	top: -1em;
  	position: absolute;
  	z-index: 2;
  	border-right: solid 1em #d0d0d0;
  	border-top: solid 1em transparent;
  }
  @include drop-shadow(tall, 1);
}

.print-only {
	display: none;
}
@media print {
	
	.print-only {
		display: inherit;
	}

	.site-header,
	.page-sidebar,
	.support-bar,
	.site-footer,
	.no-print {
		display: none;
	}

	blockquote,
	.page-content__callout {
		font-style: oblique;
		border: none;
		font-size: 14pt;
	}

	.page-content__callout::after {
		/*content: none;*/
		margin-top: 4em;
	}

	@page { margin: 2cm; }

	.page-container {
		width: 100%;
		margin: 0;
	}

	body {
		font-family: 10pt Georgia, "Times New Roman", Times, serif;
		line-height: 1.4;
		background: #fff !important;
		color: #333;
	}

	/* Defining all page breaks */
	a {
	    page-break-inside:avoid
	}
	blockquote {
	    page-break-inside: avoid;
	}
	h1, h2, h3, h4, h5, h6 { page-break-after:avoid;
	     page-break-inside:avoid }
	img { page-break-inside:avoid;
	     page-break-after:avoid; }
	table, pre { page-break-inside:avoid }
	ul, ol, dl  { page-break-before:avoid }

	/* Displaying link color and link behaviour */
	a:link, a:visited, a {
	background: transparent;
	color: #520;
	font-weight: bold;
	text-decoration: underline;
	text-align: left;
	}

	a {
	    page-break-inside:avoid
	}

	a[href^=http]:after {
	      content:" < " attr(href) "> ";
	}

	a::after > img {
	   content: "";
	}

	article a[href^="#"]:after {
	   content: "";
	}

	a:not(:local-link):after {
	   content:" < " attr(href) "> ";
	}

	iframe, ins {
    display: none;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap;
	}
	h1 {
		font-size: 20pt;
	}
	h2, h3, h4 {
		margin-top: 1em;
	}

	// Removed after state since this can't insert the current year.
	.print-footer {
	// .page-content::after {
	// 	content: "© 1995—2019 MidAmerican Energy Company. All Rights Reserved.";
		color: #999 !important;
		font-size: 1em;
		padding-top: 30px;
		padding-left: 1em;
	}

	.page-header__container {
		padding: 1em;
		border: solid 1px #aaa;
	}
	.page-header__title {
		color: #000 !important;
		margin-bottom: 0;
	}
	.page-content__intro {
		margin-bottom: 0;
		padding-bottom: 0;
	}
}
.alert-banner {
	@include drop-shadow(base);
	background-color: $warning;
	height: 0;
	overflow: hidden;

	&.is-visible {
		height: auto;
		overflow: visible;
	}

	&__image {
		background-color: $black;
	}

	&__content {
		font-size: rem(15px);
		margin-top: $spacer;
		max-width: 70%;
		@include media-breakpoint-up(sm) {
			max-width: 75%;
		}
		@include media-breakpoint-up(lg) {
			padding: $spacer * 1.5 $spacer * 4;
			font-size: 1rem;
			margin-top: 0;
		}
		.btn {
			@include media-breakpoint-down(lg) {
				margin-bottom: $spacer;
			}
			@include media-breakpoint-up(md) {
				&:not(:last-child) {
					margin-right: $spacer;
				}
			}
			@include media-breakpoint-only(md) {
				padding: {
					top: 0.75rem;
					right: 1.25rem;
					bottom: 0.75rem;
					left: 1.25rem;
				}
			}
		}
		.btn-black {
			@include drop-shadow(tall, 0.9);
		}

		.btn-ghost {
			@include button-ghost($black);
			line-height: 1.325;
		}
	}
	&__buttons {
		flex: 1 0 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		@include media-breakpoint-only(sm) {
			align-items: center;
			.btn {
				width: 12em;
			}
		}
	}
	&__close {
		@extend .l-flex, .l-justify-center;
		align-items: flex-start;
		margin-top: 1em;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
			align-items: center;
		}
		.isvg {
			&::before {
				max-width: 100%;
				@include media-breakpoint-down(sm) {
					transform: scale(0.75);
				}
			}
			@include media-breakpoint-up(sm) {
				padding-right: $spacer;
			}
		}
	}
}



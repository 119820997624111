.thead-dark {
	th {
		font-weight: 600;
	}
}

.cell {
	display: flex;
	justify-content: space-around;
	th & {
		justify-content: flex-end;
	}
}
thead .cell {
	text-align: center;
	align-items: center;
	justify-content: center;
	// font-size: rem(10px);
}
tbody .im {
	padding-left: 4px;
}

.centered-cell {
	@extend .cell;
	justify-content: center;
}

.table {
	a[data-toggle="tooltip"] {
		opacity: 0.5;
		&:hover {
			opacity: 1.0;
		}
	}
	// tbody th {
	// 	border-top: 0;
	// }
}

.table thead th {
	border-top-width: 0;
	border-bottom-color: $light;
	padding: {
		top: $spacer / 2;
		bottom: $spacer / 2;
	}
}

.table-striped tbody {
	// tr:nth-of-type(2n+1) {
	// 	background-color: lighten($lightest, 5%);
	// }
	tr.row-nostripe {
		background-color: transparent;
	}
}


.table-row__border-bottom {
	border-bottom: solid 1px $light;
}

.table-row__border-bottom--heavy {
	border-bottom: solid 2px $primary;
}
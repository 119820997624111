.drop-shadow {
	@include drop-shadow(base);

	&--dark {
		@include drop-shadow(base, 0.85);
	}

	&--tall {
		@include drop-shadow(tall);
	}
}
.site-container {
	@include media-breakpoint-up(3xl) {
		@include stylish-background;
	}
}

.page-container {
	max-width: 1440px;
	margin: 0 auto;
	background-color: white;
	overflow-x: hidden;
	position: relative;
	@include media-breakpoint-up(3xl) {
		box-shadow: 0 0 25px -10px black;
	}
}

.content-container {
	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&:not(.no-sidebar){
		@include media-breakpoint-up(md) {
			background: {
				image: url('/static/images/backgrounds/bg-trigon.svg');
				position: bottom left;
				size: 650px;
				repeat: no-repeat;
			}
		}
	}
}

.page-header {
	@extend .l-flex;
	color: white;
	background-color: $black;
	margin-bottom: $spacer;
	position: relative;
	z-index: 3;
	padding: $spacer/2 $spacer $spacer/4;
	@include media-breakpoint-up(md) {
		@include pre-triangle($black, 3em);
		&::before {
			-ms-transform: translateX(-47px);
		}
		padding: 0;
	}
	@include media-breakpoint-up(lg) {
		@include pre-triangle($black, 4.25em);
		&::before {
			-ms-transform: translateX(-67px);
		}
		width: calc(100% - #{$spacer / 2});
		margin-left: $spacer / 2;
	}


	&__container {
	 width: 100%;
	}
	&__title {
		@include heading;
		margin-bottom: 0;
		font-size: rem(22.5px);
		float: left;
		padding-top: 0.5rem;
		@include media-breakpoint-up(md) {
			padding-top: 0.75rem;
			max-width: 80%;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 1.4rem;
			font-size: rem(27px);
		}
	}
	&__actions {
		float: right;
		@extend .l-flex;
		@extend .l-justify-end;
		position: relative;
		margin-right: 1rem;
		padding-top: 0.75rem;
		@include media-breakpoint-up(lg) {
			padding-top: 1.75rem;
		}
	}
	.isvg {
		opacity: 0.5;
		@include media-breakpoint-up(md) {
			margin-right: $spacer;
		}
	}
}
.no-sidebar .page-header {
	@include media-breakpoint-up(md) {
		width: auto;
		margin-left: auto;
	}
}
.page-content {
	padding-bottom: $spacer * 2;
	position: relative;
	@include media-breakpoint-up(md) {
		flex: 1;
	}


	&__body {
		padding: $spacer / 2 $spacer;
		// max-width: 75%;
		// margin: {
		// 	left: auto;
		// 	right: auto;
		// }
		@supports(display: grid) {
			max-width: 86ch;
		}
		@include media-breakpoint-up(lg) {
			padding: $spacer * 1.5 $spacer * 3;
		}
		h2 {
			font-family: $font-family-headings;
			margin-bottom: 0.5rem;
		}
		p {
			margin-bottom: $spacer * 2;
		}
		> p:last-child {
			@extend .post-trigon;
		}
		@include media-breakpoint-down(lg) {
			max-width: 100vw;
		}
	}

	.no-sidebar & {
		background: {
			image: url('/static/images/backgrounds/bg-trigon--right.svg');
			position: right 20%;
			size: 50vw;
			repeat: no-repeat;
		}
		.page-content__body {
			max-width: 86ch;
			padding: {
				left: 5%;
				right: 5%;
			}
			@include media-breakpoint-up(lg) {
				margin: {
					left: auto;
					right: auto;
				}
			}
			@include media-breakpoint-up(xl) {
				// width: 57.8vw;
				max-width: 980px;
				margin: auto calc(20.5vw + 2.125em);
				// margin: auto;
				padding: {
					left: 0;
					right: 0;
				}
			}
			@include media-breakpoint-up(3xl) {
				max-width: 980px;
			}
		}
		.page-header {
			@include media-breakpoint-up(lg) {
				margin-left: 13.5vw;
			}
			@include media-breakpoint-up(xl) {
				margin-left: 20.5vw;
				// margin-left: 11.5vw
			}
		}
	}
}

.page-content__intro,
.lead {
	font-size: 120%;
	padding-bottom: 1em;
	&::after {
		content: "";
		display: block;
		background-color: rgba($primary, 0.5);
		height: 2px;
		width: 15%;
		margin: {
			top: 2em;
			left: auto;
			right: auto;
		}
	}
}

.page-content__callout,
.page-content__intro--stylized {
	clear: both;
	display: block;
	margin: $spacer * 4 $spacer * 2;
	@include media-breakpoint-up(md) {
		margin: $spacer * 5 $spacer * 4;
	}
	@include media-breakpoint-up(xl) {
		// margin-top: $spacer * 6;
		margin-bottom: $spacer * 3;
	}
	&::before,
	&::after {
		z-index: 0;
	}
	&::after {
		content: "";
		display: block;
		position: absolute;
		background-color: $accent;
		height: 1px;
		width: 95%;
		margin-top: $spacer;
		transform: translateX(-20%);

		@include media-breakpoint-up(md) {
			transform: translateX(-13%);
		}
		@include media-breakpoint-up(xl) {
			margin-top: $spacer * 2;
			transform: translateX(-45%);
		}
	}
}
div, p {
	+ .page-content__callout,
	+ .page-content__intro--stylized {
		margin-top: $spacer * 4;
	}
}

.no-sidebar .page-content__callout,
.page-content__intro--stylized {
	@extend .heading--thin;
	&::before {
		content: "";
		display: block;
		position: absolute;
		background-color: $accent;
		height: 1px;
		width: 95%;
		transform: translate(-$spacer, -$spacer * 2);
		margin-top: $spacer;
		@include media-breakpoint-up(md) {
			transform: translate(-$spacer * 2, -$spacer * 2);
		}
		@include media-breakpoint-up(xl) {
			transform: translate(-$spacer * 8, -$spacer * 3);
		}
	}
}

.page-content__intro--stylized {
	margin-top: $spacer;
	@include media-breakpoint-down(sm) {
		font-size: 1rem;
		margin: {
			left: $spacer;
			right: $spacer;
			bottom: $spacer * 2;
		}
	}
	p {
		margin-bottom: 0;
	}
	footer {
		margin-top: $spacer*1.5;
	}
}

.no-sidebar {
	.page-content {
		max-width: 100%;
		padding: {
			left: 0;
			right: 0;
		}
	}
	.fullwidth-image {
		width: 100%;
		max-width: none;
	}
	.fullwidth-container {
		position: relative;
		display: block;
		@include breakout;
		overflow-x: hidden;
		max-width: calc(980px + 50%);
		@include media-breakpoint-up(xl) {
			max-width: calc(1080px + 50%);
		}
		&::before {
			position: absolute;
			display: block;
			z-index: 2;
			bottom: $spacer;
			left: 0;
			width: 150vw;
			transform: translateX(-50vw);
			height: 100%;
			background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 0.9) 20%, rgba(white, 0) 50% );
			@include media-breakpoint-up(md) {
				content: "";
			}
		}
		.fullwidth-caption {
			margin-left: $spacer;
			padding-left: 0;
			margin-bottom: $spacer;
			@include media-breakpoint-up(md) {
				position: absolute;
				z-index: 3;
				bottom: $spacer * 2;
				margin-bottom: 0;
			}
			@include media-breakpoint-up(lg) {
				bottom: $spacer * 3;
			}
		}
	}
}
// Code that makes the change recognizeable for TFS

.card {
	align-items: flex-start;
	border: none;
	flex-wrap: wrap;
}

.card--invisible {
	background-color: transparent;
	border: none;
}

.card-title {
	@include heading;
	font-size: rem(24px);
	margin-bottom: $spacer / 2;
	@include media-breakpoint-only(lg) {
		min-height: 86px;
	}
	@include media-breakpoint-up(xl) {
		min-height: 52px;
	}
	@include media-breakpoint-up(4xl) {
		min-height: 0;
	}
	a {
		text-decoration: none;
	}
}

.card-content {
	padding: $spacer;
	@include media-breakpoint-up(large) {
		padding: $spacer*1.5 $spacer*2;
	}
}

.card-text {
	@include media-breakpoint-up(sm) {
		margin-bottom: 0;
	}
	@include media-breakpoint-up(md) {
		min-height: 160px;
	}
	@include media-breakpoint-only(lg) {
		min-height: 200px;
	}
	@include media-breakpoint-up(xl) {
		min-height: 160px;
	}
	@include media-breakpoint-up(4xl) {
		min-height: 120px;
	}
}
.card-body {
	// IE fix:
	max-width: 100%;
}

.card-body--reverse {
	max-width: 100%;
	padding: {
		top: 0.75rem;
	}
	// @include media-breakpoint-up(lg) {
	// 	padding: {
	// 		left: $spacer;
	// 		right: $spacer;
	// 	}
	// }
	@include media-breakpoint-only(lg) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

}

.news-cards {
	.card {
		height: 100%;
	}
	/*
	.card-body {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}
	*/
}

.card-tab {
	border: solid 2px $primary;
	border-left-width: 10px;
	padding: {
		top: $spacer;
		right: $spacer;
		left: $spacer;
		bottom: $spacer * 2;
	}
	margin-bottom: $spacer;
}

@include media-breakpoint-down(md) {
	.reverse {
	.container,
		div[class^="col"] {
			padding: {
				left: 0;
				right: 0;
			}
		}
		.row {
			margin: {
				left: 0;
				right: 0;
			}
		}
	}

	div[class^="col"]:not(:last-child) .card {
		margin-bottom: $spacer * 2;
	}
}

.article-blocks {
	@extend .row;
	margin-bottom: $spacer * 3;

	&--vertical {
		flex-direction: row;
	}
}


.article-block {
	.article-blocks--horizontal & {
		@include make-col-ready();
		@include media-breakpoint-up(sm) {
			@include make-col(5);
		}
		@include media-breakpoint-up(md) {
			@include make-col(4);
			display: flex;
			flex-direction: column;
				// justify-content: space-between;
			.article-block__outer {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				&:last-child {
					flex: 1;
				}
			}
			.article-block__inner {
				height: 100%;
			}
		}
		@include media-breakpoint-down(sm) {
			padding: $spacer;
			margin: $spacer;
			@include drop-shadow(tall, 0.325);
			// border-radius: 1em;

			.btn {
				margin-bottom: 0;
			}
		}

	}
	.article-blocks--vertical & {
		border-bottom: solid 1px $light;
		padding-bottom: $spacer;
		margin-bottom: $spacer;
		margin-left: $spacer;
		margin-right: $spacer;

		.article-block__thumbnail {
			max-width: 120px;
			@include media-breakpoint-up(md) {
				max-width: 100%;
			}
		}
		.article-block__outer:first-child {
			float: left;
			margin-right: $spacer;
		}
		.article-block__snippet {
			clear: left;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			margin-left: 0;
			margin-right: 0;
			@include make-col-ready();
			@include make-col(12);
			.article-block__outer:first-child {
				float: none;
				flex-basis: 35%;
			}
			.article-block__outer:last-child {
				flex-basis: 100%;
				margin-right: 0;
			}
		}
	}

	&__thumbnail {
		margin-bottom: $spacer/2;
	}
	&__category,
	&__date {
		font-size: 80%;
		margin-bottom: $spacer/2;
	}
	&__snippet {
		margin-bottom: $spacer;
	}
}
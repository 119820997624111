.site-header__branding {
	@include media-breakpoint-down(md) {
		height: rem(80px);
		background-color: #fff;
	}

}
.site-logo {
	display: block;
	@include media-breakpoint-up(xl) {
		margin-top: 1em;
		margin-left: 1em;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.site-logo__image {
	@include media-breakpoint-up(lg) {
		width: 100%;
	}
	@include media-breakpoint-up(xl) {
		max-width: rem(360px);
	}
}

.slit {
	@include media-breakpoint-down(md) {
		display: flex;
		justify-content: flex-end;
		padding-right: $spacer * 1.25;
		background: {
			color: white;
			image: url('/static/images/backgrounds/slit.jpg');
			position: bottom left;
			repeat: no-repeat;
		}
	}
}

.navbar-toggler {
	color: $accent;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&__label {
		display: block;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 0.625rem;
	}
}

.hamburger {
	padding: {
		left: 0.5rem;
		right: 0;
		top: 0.5rem;
		bottom: 0;
	}

	&-inner,
	&-inner::before,
	&-inner::after {
		background-color: $accent;
	}
}

// Desktop

.site-header {
	@include media-breakpoint-up(lg) {
		margin-bottom: 1.25rem;
		z-index: 1080;
		position: relative;
	}
}

.feature-page .site-header {
	@include media-breakpoint-up(lg) {
		position: absolute;
		width: 100%;
	}
}

.outage-link {
	color: $midamerican-darkgray;
	text-decoration: none;
	&:hover {
		color: $midamerican-darkgray;
		text-decoration: underline;
	}
}

.action-bar--mobile {
	height: rem(50px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	-ms-flex-flow: row;
	padding: {
		left: $spacer;
		right: $spacer;
	}
	@include media-breakpoint-only(sm) {
		font-size: 80%;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}

	a, button {
		color: white;
		display: inherit;
		vertical-align: text-bottom;
		&:active,
		&:focus {
			opacity: 0.5;
		}
	}

	.isvg {
		margin-left: 1rem;
		height: rem(24px);
		@include media-breakpoint-only(md) {
			margin-left: 2rem;
		}
		&::before {
			height: 18px;
			width: 18px;
			@supports(display: grid) {
				height: auto;
				width: auto;
			}
		}
	}
	.isvg-warning {
		height: rem(28px);
	}

	.tablet-only {
		display: none;
		@media only screen and (min-width: 598px) {
			display: inline-block;
			height: 1.25em;
			font-size: rem(16px);
			margin-left: $spacer / 2;
		}
	}
}

.contact-phone {
	font-family: $font-family-headings;
	color: $dark;
	text-decoration: none;

	.im-phone {
		transform: scale(0.8);
		// @include media-breakpoint-up(md) {
		// 	transform: translateY(4px);
		// }
		@include media-breakpoint-up(lg) {
			transform: translateY(4px);
		}
	}
	@include media-breakpoint-up(md) {
		font-size: 0.85rem;
	}
	@include media-breakpoint-up(2xl) {
		font-size: 1rem;
	}
	@include media-breakpoint-down(md) {
		font-weight: bold;
	}
}

.action-bar--desktop {
	margin: {
		top: 0.75rem;
		bottom: 0.75rem;
		right: 1em;
	}
	height: 2.5rem;
	.btn-w-icon {
		font-size: rem(12px);
		// font-weight: normal;
		padding-left: $spacer / 1.5;
		@include media-breakpoint-up(md) {
			height: calc(2.25rem + 3px);
		}
		@include media-breakpoint-up(2xl) {
			font-size: 0.75rem;
			// font-weight: bold;
		}
	}
	.isvg-user {
		min-width: 1rem;
		min-height: 1rem;
	}
	.isvg-warning-o {
		height: 2em;
	}
	.search__input {
		@include media-breakpoint-only(lg) {
			max-width: 160px;
		}
	}
	.bs-popover-bottom .arrow::after {
		border-bottom-color: $warning;
	}
	.popover {
		border: 0;
		max-width: none;
		.link--bold {
			text-decoration: none;
		}
	}
	.popover-body {
		padding: $spacer $spacer;
		background-color: $warning;
		.modal-dialog {
			margin: 0;
		}
		.modal-content {
			@extend .l-flex--column;
			@extend .l-justify-between;
			@include media-breakpoint-up(lg) {
				min-width: 240px;
			}

			> div:last-child {
				margin-top: 0.5em;
			}
		}
		// .modal-dialog {
			// width: 180px;
		// }
		.heading--book,
		.heading--bold,
		.link--bold {
			font-size: rem(24px);
		}
		.heading--book {
			margin-bottom: 0;
		}
		.heading--bold,
		.link--bold {
			color: $black;
			font-weight: bold;
			// margin-left: rem(-24px);
		}
		.isvg::before {
			height: 24px;
			width: 24px;
			margin-top: 0.125em;
			margin-right: 0.125em;
		}
		.isvg-flame--blue::before {
			width: 18px;
		}
		h2,
		.close-button {
			display: none;
		}
	}
}

.action-bar__item {
	margin-left: 0.75rem;
}
// Code that makes the change recognizeable for TFS
.text-align-right {
	text-align: right;
}

.warning {
	background-color: lighten($accent, 40%);
	color: black;
	border: solid 1px $accent;
	padding: $spacer / 2 $spacer;
	display: flex;
	margin-bottom: $spacer / 1.5;
	line-height: 1.325;
	.im,
	.isvg {
		margin-right: 0.5em;
		position: relative;
		top: 2px;
	}
	@include media-breakpoint-down(lg) {
		flex-basis: 100%;
		margin-left: $spacer;
		align-items: center;
	}
}
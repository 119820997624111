.article-meta {
	margin-top: $spacer;
	padding-top: $spacer;
	border-top: solid 1px $dark;
	font-size: 85%;

	&__date {
		color: $midamerican-medgray;
	}

	&__tag-container {
		margin-top: $spacer;
		padding-left: 15px;
		overflow: auto;
	}

	&__tag {
		float: left;
		background-color: lighten($light, 20%);
		color: $dark;
		margin-right: 2rem;
		margin-bottom: 1rem;
		padding: 4px 10px;
		text-decoration: none;
		position: relative;
		font-size: 16px;
		line-height: 22px;
		height: 32px;
		&:hover {
			color: black;
			background-color: $light;
			text-decoration: none;

			&::before {
				border-right-color: $light;
			}
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: -16px;
			top: 0px;
			border-right: solid 16px lighten($light, 20%);
			border-top: solid 16px transparent;
			border-bottom: solid 16px transparent;
		}

		&::after {
			content: "";
			background-color: white;
			height: 0.325rem;
			width: 0.325rem;
			border-radius: 50%;
			position: absolute;
			left: -0.25rem;
			top: calc(.5rem + (.325rem/2) + 2px);
		}
	}
}

.article-author {
	.isvg {
		margin-right: 1ch;
		position: relative;
		top: calc(.25em/2 + 2px);
	}
	font-size: .875rem;
	vertical-align: middle;
	line-height: 1;
	color: $midamerican-medgray;
}
.primary-actions {
	position: relative;
	@include media-breakpoint-only(md) {
		transform: scale(.75);
		top: 5em;
		right: 1rem;
	}
	@include media-breakpoint-only(lg) {
		transform: scale(0.85);
	}
	@include media-breakpoint-up(md) {
		position: absolute;
		z-index: 4;
		width: rem(320px);
	}
	@include media-breakpoint-up(lg) {
		top: 5em;
		right: $spacer / 2;
	}
	@include media-breakpoint-up(xl) {
		top: 8em;
		right: 5%;
	}
	@include media-breakpoint-down(sm) {
		background: {
			image: url('/static/images/backgrounds/winter-turbines-wide.jpg');
			repeat: no-repeat;
			size: cover;
			position: center left;
		}
		padding: {
			top: 0.5rem;
			left: 7%;
			right: 7%;
			bottom: 1em;
		}
		border-bottom: solid $spacer $midamerican-darkgray;
	}
	@include media-breakpoint-down(xs) {
		padding: {
			left: 3%;
			right: 3%;
		}
	}
}

/* .bg-video__container {
	width: 100%;
	height: 90vh;
	overflow: hidden;
	@include media-breakpoint-up(sm) {
		display: none;
	}
	.bg-video {
		min-width: 100%;
	}
} */

.login-card {
	@include drop-shadow(tall, 0.75);
	width: 100%;
	max-width: rem(320px);
	margin: {
		top: $spacer / 4;
		left: auto;
		right: auto;
	}
	@include media-breakpoint-up(sm) {
		margin-top: $spacer;
		width: 100%;
	}
	@include media-breakpoint-up(md) {
		margin-top: 0;
	}
	.heading--thin {
		margin-bottom: 0;
	}
	.btn {
		width: 100%;
	}
	a {
		font-size: rem(14px);
	}
	.form-check-label {
		font-size: 1rem;
	}
	.card-body {
		width: 100%;
	}
	.form-group {
		margin-bottom: 0.125rem;
	}
	.custom-checkbox {
		top: 2px;
	}
}

.quick-actions {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	max-width: rem(320px);
	margin: {
		left: auto;
		right: auto;
	}
}

.quick-actions__panel {
	@extend .btn-primary;
	@extend .l-flex--column;
	@extend .l-justify-end;
	@extend .l-align-center;
	width: rem(96px);
	height: rem(96px);
	font-size: 1rem;
	text-decoration: none;
	transition: all 0.25s;
	padding: 0.25em;

	&:hover {
		color: $white;
		text-decoration: none;
		transform: translateY(-0.125rem);
	}

	.quick-actions__figure {
		@extend .l-flex--column;
		@extend .l-justify-between;
		@extend .l-align-center;
		margin-bottom: $spacer / 2;
	}
}

.quick-actions__figure {
	.isvg-bolt,
	.isvg-handcart {
		&::before {
			height: 2.5em;
			width: 2.5em;
			// margin-top: 0.5rem;
			// margin-bottom: -0.5em;
		}
	}
}

//=================================
// FUNCTIONS
//=================================


//---------------------------------
// General
//---------------------------------

// --
// Gets a font stack
// @param  {String} $name    The name (key) of the font in the $fonts map
// @param  {String} $weight  The font weight name (ex. light)
// @return {String}          The font stack
// --
@function get-from-map($map, $key, $variant) {
  @if map-has-key($map, $key) {
    $value: map-get($map, $key);

    @if (length($value) > 1) { // This must be a map
      @return map-get(map-get($map, $key), $variant);
    } @else {
      @return $value;
    }
  } @else {
    @warn "Couldn't find `#{$key}`.";
  }
}

//---------------------------------
// Fonts
//---------------------------------

// --
// Get the numeric font weight value from a name
// @param  {String} $name The name of the weight (ex. heavy)
// @return {String}       The weight value
// --
@function get-font-weight($name) {
  @return map-get($weights, $name);
}

// --
// Gets a font stack
// @param  {String} $font_name    The name (key) of the font in the $fonts map
// @param  {String} $weight       The font weight name (ex. light)
// @return {String}               The font stack
// --
@function get-font-stack($font_name, $weight: normal) {
  $font: get-from-map($fonts, $font_name, $weight);

  @if $font {
    @return unquote($font);
  } @else {
    @warn "Couldn't find a font named `#{$font_name}`.";
  }
}



//---------------------------------
// Colors
//---------------------------------

// --
// Returns a color with an optional tone from the map
// @param  {String}  $color_name   The base name of the color
// @param  {String}  $tone         The tone of that color or 'base'
// @return {String}                The matching color's value from the map
// --
@function get-color($color_name, $tone: base) {
  $color: get-from-map($colors, $color_name, $tone);

  @if $color {
    @return $color;
  } @else {
    @warn "Couldn't find a color named `#{$color_name}`.";
  }
}

// Tint a color by mixing it with white.
@function tint($color, $percent) {
  @return mix(#fff, $color, $percent);
}


//---------------------------------
// Radii
//---------------------------------

@function get-radius($radius_name, $roundness: base) {
  $radius: get-from-map($radius, $radius_name, $roundness);

  @if $radius {
    @return $radius;
  } @else {
    @warn "Couldn't find a radius named `#{$radius_name}`.";
  }
}

//---------------------------------
// Gutters
//---------------------------------

@function get-gutter($gutter_name, $width: base) {
  $gutter: get-from-map($gutter, $gutter_name, $width);

  @if $gutter {
    @return $gutter;
  } @else {
    @warn "Couldn't find a gutter named `#{$gutter_name}`.";
  }
}



//---------------------------------
// Shadows
//---------------------------------

@function get-radius($radius_name, $roundness: base) {
  $radius: get-from-map($radius, $radius_name, $roundness);

  @if $radius {
    @return $radius;
  } @else {
    @warn "Couldn't find a radius named `#{$radius_name}`.";
  }
}



//---------------------------------
// Animation
//---------------------------------

@function get-timing($timing_name, $speed: base) {
  $timing: get-from-map($timing, $timing_name, $speed);

  @if $timing {
    @return $timing;
  } @else {
    @warn "Couldn't find a timing named `#{$timing_name}`.";
  }
}


//---------------------------------
// Units
//---------------------------------

// --
// Converts pixels to ems
// @param  {Number} $px-values  The pixel value to convert
// @param  {Number} $font-size  The context for which to set the em value
// @return {Number}             The new em value
// --
@function em($px-values, $font-size: $body-font-size) {
  // Create an empty list that we can dump values into
  $em-values: ();
  @each $value in $px-values {
    // Check to see if value is 0, auto, or inherited
    // If so, do nothing and output value
    @if $value == 0 or $value == auto or $value == inherit {
      $em-values: append($em-values, $value);
    // If the value is a pixel, convert to em value
    } @else if unit($value) == 'px' {
      $em-values: append($em-values, #{($value/$font-size)}em);
    // For everything else, keep as is
    } @else {
      $em-values: append($em-values, $value);
    }
  }
  @return $em-values;
}

// --
// Converts pixels to rems
// @param  {Number} $px-values  The pixel value to convert
// @param  {Number} $font-size  The context for which to set the rem value
// @return {Number}             The new rem value
// --
@function rem($px-values, $font-size: $base-font-size) {
  // Create an empty list that we can dump values into
  $rem-values: ();
  @each $value in $px-values {
    // Check to see if value is 0, auto, or inherited
    // If so, do nothing and output value
    @if $value == 0 or $value == auto or $value == inherit {
      $rem-values: append($rem-values, $value);
    // If the value is a pixel, convert to em value
    } @else if unit($value) == 'px' {
      $rem-values: append($rem-values, #{($value/$font-size)}rem);
    // For everything else, keep as is
    } @else {
      $rem-values: append($rem-values, $value);
    }
  }
  @return $rem-values;
}
.skipto--login,
.skipto--content,
.skipto--menu,
.skipto--search {
	@include sr-only;
	@include sr-only-focusable;
}

.link-arrow::after {
	display: inline-block;
	margin-left: 0.325em;
	content: "";
	border: {
		left: 0.3em solid $secondary;
		right: 0;
		top: 0.3em solid transparent;
		bottom: 0.3em solid transparent;
	}
	transition: all 0.25s;
}
.link-arrow:hover::after {
	border-left-color: lighten($secondary, 10%);
	margin-left: 0.5em;
}

a[href^="tel:"] {
//	text-decoration: none;
}

.nav-pipes {
	.nav-item:not(:last-child) {
		border-right: solid 2px $midamerican-medgray;
	}
	.nav-link {
		padding: {
			top: 0;
			bottom: 0;
			left: 0.5rem;
			right: 0.5rem;
		}
		@include media-breakpoint-up(sm) {
			padding: {
				left: 0.75rem;
				right: 0.75rem;
			}
		}
	}
	// .nav-item:first-child .nav-link {
	// 	padding-left: 0;
	// }
	// .nav-item:last-child .nav-link {
	// 	padding-right: 0;
	// }
}

.nav-strong {
	font-weight: bold;
}

.page-item {
	&:first-child .page-link {
		border-top-left-radius: 0.5em;
		border-bottom-left-radius: 0.5em;
	}
	&:last-child .page-link {
		border-top-right-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
	}
	&.active .page-link {
		border-color: $secondary;
		/* font-weight: bold;
		color: $secondary;
		cursor: default;
		background-color: white; */
		color: white;
		background-color: $secondary;
	}
}

.page-link {
	color: $secondary;
	border-color: $secondary;
	&:hover {
		color: white;
		background-color: $secondary;
		border-color: $secondary;
	}
}

a[data-toggle="tooltip"] {
	text-decoration: none;
	color: $light;
	font-size: rem(16px);
	&.im {
		position: relative;
		top: 3px;
		margin-left: 2px;
	}
}

.link-disabled {
	cursor: text;
	color: $lighter !important;
}

// custom underlines that animate (but cause issues on inline-block elements)
// .page-content a:not(.btn) {
// 	text-decoration: none;
//   position: relative;
//   transition: color 0.25s cubic-bezier(0,0.2,0.2,1);
//   display: inline-block;
//   &::before,
//   &::after {
//     content: "";
//     position: absolute;
//     display: inline-block;
//     bottom: -1px;
//     left: 0;
//     height: 2px;
//     transition: all 0.25s cubic-bezier(0,0.2,0.2,1);
//   }
//   &::before {
//     width: 100%;
//     background-color: $secondary;
//   }
//   &::after {
//     width: 0;
//     left: 50%;
//     background-color: lighten($secondary, 20%);
//   }

//   &:hover::after {
//       width: 100%;
//       left: 0;
//   }
// }
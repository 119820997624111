@mixin drop-shadow($depth, $opacity: 0.5) {
	$shadow-depth: map-get($shadows, $depth);
	box-shadow: $shadow-depth rgba(0,0,0, $opacity);
}

@mixin heading {
	font: {
		family: $font-family-headings;
		weight: bold;
		size: 1.625rem;
	}
}

@mixin callout {
	font:{
		family: $font-family-headings;
		weight: light;
		size: 2rem;
	}
}

@mixin button($bgcolor: black, $fgcolor: white) {
	border: 0;
	background-color: $bgcolor;
	color: $fgcolor;
	text-decoration: none;
	font: {
		size: rem(18px);
		weight: bold;
	}
	padding: 0.5em 1em;
	transform: translateY(0);
	transition: all 0.125s;
	&:hover {
		transform: translateY(-0.125rem);
		background-color: lighten($bgcolor, 15%);
	}
}

@mixin button-material-hover {
	&::before {
		content: "";
		display: block;
		width: 0;

		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;

		background-color: rgba(white, 0.2);
		padding-top: 0;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transition: width .2s ease-out, padding-top .2s ease-out;
	}

	&:hover,
	&[aria-describedby] {
		&::before {
			width: 120%;
			padding-top: 120%;
		}
	}
}

@mixin button-ghost($color) {
	font-weight: bold;
	color: $color;
	border: solid 2px $color;
	background-color: transparent;
}

@mixin spread {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
}

@mixin breakout {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
}

@mixin pre-triangle($color : $dark, $size: 1rem) {
	&::before {
		content: "";
		display: block;
		height: 0;
		width: 0;
		transform: translateX(calc(-#{rem($size)} + 1px));
		-ms-transform: translateX(-34px);
		@include media-breakpoint-up(xl) {
			-ms-transform: translateX(-38px);
		}
		margin-right: calc(#{-$size / 2});
		border: {
			width: calc(#{rem($size)} + 1px) 0 0 #{rem($size)};
			color: $color transparent transparent transparent;
			style: solid;
		}
	}
}

@mixin pre-caret($color: $dark) {
	&::before {
		content: "";
		display: inline-block;
		height: 0;
		width: 0;
		// transform: translateX(calc(#{-$size + 1}));
		margin-right: $spacer / 2;
		border: {
			width: 8px 0 8px 8px;
			color: transparent transparent transparent $color;
			style: solid;
		}
	}
}
@mixin caret($color: $dark) {
	content: "";
	display: inline-block;
	position: relative;
	height: 0;
	width: 0;
	margin-right: $spacer;
	border: {
		width: 8px 0 8px 8px;
		color: transparent transparent transparent $color;
		style: solid;
	}
}

@mixin stylish-background {
	background: {
		image: url('/static/images/backgrounds/bg-trigon--reverse.svg'), linear-gradient(135deg, $gray-900, $gray-600);
		size: contain, cover;
		position: bottom right, center center;
		repeat: no-repeat;
	}
}

-moz-::selection,
::selection {
	// background-color: $gray-400;
	background-color: $dark;
	color: white;
}

label {
	font: {
		size: rem(14px);
		weight: bold;
	}
	margin-bottom: 0;
}

/* .futura-light,
.futura-normal,
.futura-bold,
.futura-extrabold {
	font-style: normal;
	font-family: "futura";
}

.futura-light {
	font-weight: 100;
}
.futura-normal {
	font-weight: normal;
}
.futura-bold {
	font-weight: 600;
}
.futura-extrabold {
	font-weight: 900;
} */

.FuturaStd-Light {
	font-family: FuturaStd-Light;
	font-weight: normal;
	font-style: normal;
}
.Futura-normal-normal {
	font-family: Futura;
	font-weight: normal;
	font-style: normal;
}
.Futura-bold-normal {
	font-family: Futura;
	font-weight: bold;
	font-style: normal;
}
.FuturaStd-ExtraBold {
	font-family: FuturaStd-ExtraBold;
	font-weight: normal;
	font-style: normal;
}

.section-heading {
	@include heading;
}

.wcs-text, .wcs-text--and-image {
	margin-bottom: $spacer;
	margin-top: $spacer;
	> p:first-child {
		margin-bottom: 0;
	}
	> p:nth-child(2) {
		margin-top: $spacer;
	}
	p ~ p {
		margin-bottom: $spacer;
	}
}

.wcs-text figure {
	display: table;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
		font-size: 85%;
		color: $midamerican-medgray;
		margin: {
			top: $spacer/3;
			left: $spacer/3;
		}
	}
}

.headline {
	color: $black;
	font: {
		family: $font-family-headings;
		weight: 100;
		size: rem(32px);
	}
	text-align: left;
	line-height: 0.9;
	strong {
		display: block;
		margin-top: 0.5rem;
		margin-left: -0.125rem;
		font: {
			size: rem(54px);
			weight: 900;
		}
		text-transform: uppercase;
	}
	@include media-breakpoint-up(md) {
		font-size: rem(48px);
		strong {
			font-size: 150%;
		}
	}
}
.note {
	color: $medium;
	margin: $spacer 0;
}
.text--small {
	font-size: rem(15px);
}
.heading--thin {
	font: {
		family: $font-family-headings;
		size: 1.5rem;
		weight: 400;
	}
	@include media-breakpoint-up(lg) {
		font-weight: 100;
	}
}

.heading--book {
	font: {
		family: $font-family-headings;
		weight: 400;
	}
}

.heading--bold {
	font: {
		family: $font-family-headings;
		weight: 600;
	}
}

.heading--md {
	font: {
		weight: bold;
		size: rem(26px);
	}
	margin: {
		bottom: $spacer * 1.5;
	}
}

.heading--reset {
	font: {
		family: $font-family-default;
		weight: bold;
	}
}

.pre-trigon::before,
.post-trigon::after {
	content: '';
	background: {
		image: url('/static/images/icons/isvg-trigon--small.svg');
		size: contain;
		repeat: no-repeat;
		position: center center;
	}
	height: 0.75em;
	width: 0.75em;
	display: inline-block;
}

.pre-trigon::before {
	margin-right: 0.5rem;
}

// .post-trigon::after {

// }

.pre-indent {
	margin-left: -0.7rem;
	padding-right: 0.3rem;
}

.subtitle {
	text-align: center;
	font-style: italic;
	margin-bottom: $spacer;
}
.accordion__heading {
	a::before {
		content: "";
		display: inline-block;
		transition: transform 0.25s;
		border: {
			width: 1rem 1rem 0 1rem;
			color: $secondary transparent transparent transparent;
			style: solid;
		}
		margin-right: $spacer / 2;
		@include media-breakpoint-up(lg) {
			margin-right: $spacer;
		}
	}
	a[aria-expanded="true"] {
		color: $dark;
		font-weight: bold;
		&::before {
			border-top-color: $dark;
			transform: rotate(-180deg);
		}
		// &::after {
		// 	content: "";
		// 	display: flex;
		// 	justify-content: flex-end;
		// 	margin-left: 3rem;
		// 	margin-top: $spacer;
		// 	border-bottom: solid 1px $primary;
		// }
	}
}

.accordion-subheader__container {
	color: $medium;
	@include media-breakpoint-up(sm) {
		margin-left: 3.1rem;
	}
	p, h3 {
		margin-bottom: 0;
	}
}
.accordion__subhead {
	font: {
		family: $font-family-default;
		size: 1rem;
		weight: normal;
	}
}

.accordion__body {
	margin:  $spacer 0;
	@include media-breakpoint-up(sm) {
		margin: $spacer;
		margin-left: 3rem;
	}
	p {
		margin-bottom: 0;
	}
	.note {
		margin-bottom: $spacer;
	}
	.l-flex {
		flex-wrap: wrap;
	}
}
.search__container {
	margin-bottom: 0;
	box-sizing: border-box;
}
.search__input {
	border-color: $light;
	font-size: rem(16px);
	color: $black;
	&:active,
	&:focus {
		border-color: $black;
	}
	@include media-breakpoint-up(md) {
		height: calc(2.25rem + 3px);
	}
}
.search__button {
	@extend .d-flex, .justify-content-center, .align-items-center;
	min-width: 3rem;
	background-color: $light;
	margin-left: -1px;
	@include media-breakpoint-up(md) {
		height: calc(2.25rem + 3px);
	}
	.isvg-search {
		line-height: 0.825;
		&::before {
			@include media-breakpoint-up(lg) {
				height: 1rem;
				width: 1rem;
			}
		}
	}
	&:hover,
	&:focus {
		background-color: $black;
		transform: none;
	}
	@include media-breakpoint-up(md) {
		min-width: 2.5rem;
		min-height: 2.25rem;
	}
}

.page-content__body.search-results {
	padding-top: 0.5rem;
}
.search-results {
	p {
		margin-bottom: 0.5rem;
	}

	&__brief {
		margin-bottom: 0.5rem;
	}
	&__form {
		margin-bottom: $spacer * 2;
	}
	&__result {
		// margin-bottom: $spacer;
		padding-bottom: $spacer;
		@include media-breakpoint-up(xl) {
			padding-bottom: $spacer * 2;
			// margin-bottom: $spacer * 2;
		}
		&::after {
			content: "";
			display: block;
			background-color: rgba($light, 0.5);
			height: 2px;
			width: 5em;
			margin: {
				top: $spacer * 2;
				bottom: $spacer;
				left: auto;
				right: auto;
			}
			@include media-breakpoint-up(xl) {
				margin-top: $spacer * 4;
				margin-bottom: $spacer * 2;
			}
		}
	}

	&__meta {
		display: block;
		margin-bottom: 0.5rem;
	}

	&__pager {
		padding-top: $spacer;
	}

}
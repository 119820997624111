.l-flex {
	display: -ms-flexbox;
	-ms-flex-flow: row;
	display: flex;
}
.l-flex--column {
	@extend .l-flex;
	flex-direction: column;
}
.l-justify-center {
	justify-content: center;
	justify-items: center;
}
.l-justify-end {
	justify-content: flex-end;
	justify-items: end;
}
.l-justify-between {
	justify-content: space-between;
}
.l-justify-around {
	justify-content: space-around;
}
.l-align-start {
	align-items: flex-start;
}
.l-align-center {
	align-items: center;
}
.l-align-end {
	align-items: flex-end;
}
.l-flex-wrap {
	flex-wrap: wrap;
}
.l-flex-unit {
	flex: 1;
}

.l-grid {
	@extend .l-flex;
	@supports(display: grid){
		display: grid;
	}
}
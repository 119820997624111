.hero-slider {
	z-index: 1;
	@include media-breakpoint-up(lg) {
		min-height: 520px;
	}
	@include media-breakpoint-up(xl) {
		min-height: 600px;
	}
	@include media-breakpoint-up(3xl) {
		min-height: 720px;
	}
	@include media-breakpoint-up(4xl) {
		max-height: 800px;
		overflow: hidden;
	}
	@media only screen and (min-width: 2000px) {
		max-height: none;
	}

	.carousel-caption {
		color: $black;
		text-align: left;
		@include media-breakpoint-down(sm) {
			top: 2em;
			margin-bottom: 2em;
		}
		@include media-breakpoint-up(sm) {
			left: 5%;
		}
		@include media-breakpoint-up(lg) {
			top: 10em;
		}
		@include media-breakpoint-up(xl) {
			top: 15rem;
		}
	}
}

.carousel-item {
	img {
		width: 100%;
		height: auto;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		z-index: 2;
		display: flex;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 1.0) 50%, rgba(white, 0) 70% );

		@include media-breakpoint-up(md) {
			top: 0;
			// background-image:
			// 	linear-gradient(55deg, rgba(white, 1.0), rgba(white, 0.9) 35%, rgba(white, 0) 55% ),
			// 	linear-gradient(180deg, rgba(white, 1), rgba(white, 0.7) 5%, rgba(white, 0) 20% );
			background-image:
				radial-gradient(farthest-corner at -40% 70%, rgba(white, 1.0), rgba(white, 0.9) 40%, rgba(white, 0) 65% ),
				linear-gradient(180deg, rgba(white, 1), rgba(white, 0.7) 5%, rgba(white, 0) 20% );
		}

		@include media-breakpoint-down(sm) {
			bottom: 0;
			background-image: linear-gradient(15deg, rgba(white, 1.0), rgba(white, 1.0) 40%, rgba(white, 0) 60% );
		}
		// Should this perhaps go on the header element, provided it has a ".home" page class?
		// Would that solve the z-indexing issue of having the captions above the gradient, while
		// also having the header above the images? Or can the captions live outside their slides? No…
	}
	@include media-breakpoint-only(md) {
		min-height: 400px;
	}
}

.carousel-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@extend .spacing-md--vertical;
	@include media-breakpoint-up(md) {
		justify-content: center;
		margin: {
			top: 0;
			bottom: 0;
		}
		position: absolute;
		z-index: 20;
		bottom: -$spacer / 2;
		// right: 6%;
		right: $spacer * 3;
	}
	@include media-breakpoint-up(xl) {
		bottom: 2em;
		// right: 5%;
		right: $spacer * 7;
	}
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
	z-index: $zindex-dropdown;
}

.carousel-control-prev,
.carousel-control-next {
	position: relative;
	top: unset;
	bottom: unset;
	// Need IE-specific rules for these…
	// May want to replace with your own forward/back buttons since
	// these are partially transparent background images.
}
.carousel-indicators {
	position: relative;
	bottom: unset;
	margin: {
		left: 0.5rem;
		right: 0.5rem;
		bottom: 0;
	}
}

	// --------------------- Mobile captions: --------------------- //
.carousel-caption {
	@include media-breakpoint-down(md) {
		position: relative;
		right: 0;
		bottom: unset;
		left: 0;
		margin: {
			top: -50%;

			left: $spacer;
			right: $spacer;
			bottom: 0;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-top: -18vh;
	}
	@include media-breakpoint-only(md) {
		.headline {
			font-size: 150%;
		}
		.carousel-caption__body,
		.btn-primary {
			font-size: rem(16px);
		}
	}
}

@include media-breakpoint-down(md) {
	// --------------------- Mobile controls: --------------------- //
	.carousel-control-prev-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
	}
	.carousel-control-next-icon {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/></svg>");
	}
}


.carousel-indicators li {
	width: $spacer / 1.5;
	height: $spacer / 1.5;
	border-radius: 50%;
	background-color: transparent;
	border: solid 3px $black;
	margin: {
		left: $spacer / 2;
		right: $spacer / 2;
	}
	cursor: pointer;
	&:hover,
	&:focus,
	&.active {
		background-color: $black;
		@include media-breakpoint-up(md) {
			background-color: white;
		}
	}
	&::before,
	&::after {
		content: none;
	}
	@include media-breakpoint-up(md) {
		border-color: white;
	}
}


.carousel-caption__body {
	max-width: 54ch;
	margin: {
		top: 1.5rem;
		bottom: 1.5rem;
	}
	&--block {
		background-color: $white;
	}
	&--light {
		color: white;
	}
	@include media-breakpoint-up(md) {
		padding: 0.75rem;
		margin: {
			top: 0.75rem;
			bottom: 0.75rem;
		}
	}
	@include media-breakpoint-up(lg) {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
	}
}
// See typography.scss for (general) headline styling.
.carousel-caption__headline,
.carousel-caption__button {
	@include media-breakpoint-up(md) {
		margin-left: 0.625rem;
	}
}
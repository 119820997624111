::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $light;
	font-style: oblique;
	font-size: rem(14px);
}
::-moz-placeholder { /* Firefox 19+ */
  color: $light;
	font-style: oblique;
	font-size: rem(14px);
}
:-ms-input-placeholder { /* IE 10+ */
  color: $light;
	font-style: oblique;
	font-size: rem(14px);
}
:-moz-placeholder { /* Firefox 18- */
  color: $light;
	font-style: oblique;
	font-size: rem(14px);
}

.form-group {
	margin-bottom: 0.5em;
	.form-check {
		margin-left: inherit;
	}
}
.form-control {
	&:focus {
		outline: solid 2px $accent;
		box-shadow: none;
	}
	&:active {
		outline: none;
	}
}
.form-control--inline {
	display: inline-block;
	width: 100%;
	@include media-breakpoint-up(sm) {
		width: auto;
	}
}
.form-group--inline {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	button {
		margin-left: 0.5rem;
	}
	label {
		display: inline-block;
		margin-right: 0.5em;
	}
	input,
	select {
		flex: 1;
		@include media-breakpoint-up(lg) {
			min-width: 140px;
		}
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
		input {
			flex: none;
		}
	}
}
@include media-breakpoint-up(lg) {
	.input-line,
	input[type="date"].input-line,
	input[type="number"].input-line {
		border: none;
		border-bottom: solid 2px $light;
		box-shadow: none;
		padding: {
			top: 0.25rem;
			bottom: 0;
		}
		position: relative;
		z-index: 1;
		color: $gray-700;
		font-size: 1rem;
		&:active,
		&:focus {
			box-shadow: none;
			outline: inherit;
			color: $black;
			border-bottom-color: $dark;
		}
	}
}
.input-group-prepend {
	display: flex;
	align-items: center;
	transform: translateX(1.325em);
	z-index: 2;
}
.input-group {
	display: flex;
	align-items: center;
}
.form-line {
	&::after {
		content: "";
		height: 2px;
		width: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: transparent;
		z-index: 2;
		transition: width 0.5s;
	}
}

.formline-initial {
	// transform: scale(1.625, 1.625) translate(1.25rem, 1.025rem);
	transform: scale(1.625, 1.625) translate(20px, 15px);
	font-weight: 100;
}

.form-w-line label {
	transition: all 0.25s;
	transform-origin: top left;
	z-index: 2;
	color: $gray-600;
	position: relative;
}
.formline-active {
	label {
		color: $black;
		transform: scale(1,1) translate(0,0);
		font-weight: bold;
	}
	.form-line::after {
		width: 100%;
		background-color: $accent;
	}
}

.input-group {
	position: relative;
}

.input-icon::before {
	position: absolute;
	z-index: 2;
	left: 0.5em;
	bottom: 0.125em;
}

.input-w-icon {
	padding-left: 1.25em;
	@include media-breakpoint-down(md) {
		padding-top: 0.5em !important;
		padding-bottom: 0.5em !important;
	}
}

.input-icon--key {
	&::before {
		bottom: 0.25em;
		left: 0.325em;
	}
	+ .input-w-icon {
		padding-left: 0.75rem;
	}
}

.form-check {
	padding-left: 0;
	margin-left: -1.5em;
	margin-bottom: 1em;
}
.inline-label {
	display: inline-block;
	font-size: 1rem;
	color: $dark;
}
.inline-input {
	display: inline-block;
	padding-bottom: 0;
	margin-left: $spacer;
	.input-line {
		// padding-bottom: 0;
		padding-left: $spacer * 1.5;
		height: auto;
	}
	// &::after {
		// bottom: 4px;
	// }
}
.input-w-icon {
	// min-width: 200px;
	text-indent: 1.5rem;
	padding-left: 1.75em;
	min-width: calc(160px + 1.75em);
	@supports(display: grid) {
		text-indent: 1.5rem;
	}
}


/* -------------------------------------------------- \
 *																										*
 *								Custom checbox,						 					*
 * 		shamelessly stolen from Jimmy Gilliam: 					*
 * 		https://codepen.io/theigmo87/pen/cwHyK 					*
 *																										*
\* -------------------------------------------------- */

$checked-color: $accent;
$unchecked-color: $black;
$checkbox-height: 1rem;
$duration: .4s;

/* Checkmark style starts */

@keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2;  }
}

@keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}

input[type='checkbox']{
    display:none;
}

.custom-checkbox {
  height: $checkbox-height;
  width: $checkbox-height;
  background-color: transparent;
  border: 2px solid $unchecked-color;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  transition: border-color ease $duration/2;
  top: 3px;
  transform: translateX(1.75em);

  // @include media-breakpoint-down(sm) {
  // 	margin: 0.5em 0;
  // }

  &::before, &::after {
    box-sizing: border-box;
    position: absolute;
    height: 0;
    width: 3px;
    background-color: $checked-color;
    display: inline-block;
    transform-origin: left top;
    content: '';
    transition: opacity ease .5;
  }

  &::before {
    top:$checkbox-height * .62;
    left: $checkbox-height * .41;
    transform: rotate(-135deg);
  }

  &::after {
    top: $checkbox-height * .27;
    left: $checkbox-height * .05;
    transform: rotate(-45deg);
  }

  + .form-check-label {
  	position: relative;
		padding-left: 2.25rem;
		z-index: 2;
		cursor: pointer;
		font-weight: normal;
		@include media-breakpoint-up(4xl) {
			padding-left: 3em;
		}
  }
}

input[type='checkbox']:checked + .custom-checkbox,
.custom-checkbox.checked{
    border-color:$checked-color;

    &::after {
        height: $checkbox-height * .5;
        animation: dothabottomcheck $duration/2 ease 0s forwards;
    }

    &::before {
        height: $checkbox-height * 1.2;
        animation: dothatopcheck $duration ease 0s forwards;
    }
}
// Most styles are contained within vendor/codyhouse/_cd-mega-nav.scss
.cd-primary-nav,
.cd-primary-nav ul {
	list-style: none;
	width: 90vw;
	max-width: 400px;
	background-color: $primary;
	padding: $spacer * 0.5 $spacer * 1.25;
	transition: transform 0.25s $cubic;
}
.cd-primary-nav {
	box-shadow: -4px 0 18px rgba(black, 0.5);
	z-index: $zindex-popover;
	transform: translateX(100vw);
	transition: transform 0.25s $cubic,
							visibility 0.5s;

	&.nav-is-visible {
		transform: translateX(0);
		transition: transform 0.25s $cubic,
								visibility 0.5s;
	}
	.has-children>a::before,
	.has-children>a::after,
	.go-back a::before,
	.go-back a::after {
		background-color: $light;
	}
	a {
		@include heading;
		color: $lightest;
		font-size: rem(24px);
		border-bottom: solid 2px $medium;
		padding: $spacer;
		@include media-breakpoint-up(sm) {
			font-size: 1.625rem;
		}
		&:hover {
			color: white;
		}
	}
	.hamburger {
		color: $light;
		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			background-color: white;
		}
	}
}
.mobile-menu__heading {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	h2 {
		margin-top: 0.75rem;
		margin-left: 0.75em;
		color: $medium;
	}
}
.mobile-menu__heading h2,
.go-back a {
	@include heading;
	font-weight: 400;
}

.go-back a {
	color: $light;
	padding-top: $spacer / 2;
	padding-bottom: $spacer / 2;
}

.see-all a {
	color: $lighter;
}

.page-container::before {
	@include spread;
	z-index: -4;
	background-color: rgba(black, 0.5);
	opacity: 0;
	content: "";
}
.page-container.nav-is-visible::before {
	z-index: 4;
	opacity: 1;
	transition: opacity 0.5s $cubic;
}
@import "spacing";
@import "shadows";
@import "flex-layout";
@import "text";

.hidden-mobile {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.hidden-desktop {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.hidden-tablet {
	@include media-breakpoint-only(md) {
		display: none;
	}
}


.dropdown-arrow{
	white-space: nowrap;
	&::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid white;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
}
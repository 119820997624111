// --------------------- Vertical: --------------------- //

.spacing-sm--vertical {
	margin: {
		top: $spacer;
		bottom: $spacer;
	}
}

.spacing-md--vertical {
	margin: {
		top: $spacer * 1.5;
		bottom: $spacer * 1.5;
	}
}

.spacing-sm__internal--vertical {
	padding: {
		top: $spacer;
		bottom: $spacer;
	}
	+ .spacing-sm__internal--vertical {
		padding-top: 0;
	}
}

.spacing-md__internal--vertical {
	padding: {
		top: $spacer * 1.5;
		bottom: $spacer * 1.5;
	}
	// + .spacing-md__internal--vertical {
	// 	padding-top: 0;
	// }
}

.spacing-lg__internal--vertical {
	padding: {
		top: $spacer * 2.5;
		bottom: $spacer * 2.5;
	}
	+ .spacing-lg__internal--vertical {
		padding-top: 0;
	}
}

.spacing-sm__neg-bottom--vertical {
	margin-bottom: -$spacer;
}

.spacing-md__neg-bottom--vertical {
	margin-bottom: -($spacer * 1.5);
}

.spacing-none--bottom {
	margin-bottom: 0;
}

// --------------------- Horizontal: --------------------- //
.spacing-xs--horizontal {
	margin: {
		left: $spacer / 2;
		right: $spacer / 2;
	}
}
.spacing-sm--horizontal {
	margin: {
		left: $spacer;
		right: $spacer;
	}
}

.spacing-md--horizontal {
	margin: {
		left: $spacer * 1.5;
		right: $spacer * 1.5;
	}
}

// --------------------- Units: --------------------- //

.l-col-xs {
	width: 12%;
	min-width: 36px;
}

.l-col-sm {
	width: 16%;
	min-width: 56px;
}

.inline-block {
	display: inline-block;
}

.pos-r {
	position: relative;
}
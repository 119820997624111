img {
	max-width: 100%;
	height: auto;
}

.image-left,
.image-right {
	margin-bottom: $spacer;
}
@include media-breakpoint-up(md) {
	.image-left {
		float: left;
		margin-right: $spacer;
	}
	.image-right {
		float: right;
		margin-left: $spacer;
	}
}

.fullwidth-image {
	margin-bottom: $spacer;
}

.diamond-image {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-top: $spacer * 4;
	@include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
		justify-content: space-around;
		// margin-left: $spacer * 4;
		flex-direction: row;
		margin-top: $spacer * 5;
	}
	@include media-breakpoint-up(md) {
		transform: translateX(-$spacer);
	}
	@include media-breakpoint-up(xl) {
		@supports(display:grid) {
			.feature-page & {
				width: 120%;
			}
			.no-sidebar & {
				transform: translateX(-$spacer * 4.5);
			}
		}
	}

	&__frame {
		display: block;
		transform: rotate(45deg);
		max-width: 140px;
		max-height: 140px;
		overflow: hidden;
		margin: {
			left: auto;
			right: auto;
			bottom: $spacer * 2;
		}
		@include media-breakpoint-up(sm) {
			max-width: 100px;
			max-height: 100px;
		}
		@include media-breakpoint-up(lg) {
			max-width: 140px;
			max-height: 140px;
		}
		img {
			transform: rotate(-45deg) translateY(-24%);
			max-width: 150%;
		}
	}

	&__figure {
		margin-bottom: $spacer * 3;
		@include media-breakpoint-up(sm) {
			width: 33%;
		}
	}

	&__caption {
		font-size: rem(16px);
		@include media-breakpoint-up(md) {
			padding: {
				left: $spacer;
				right: $spacer;
			}
		}
	}
	&__heading a {
		color: $black;
	}
}

.post-icon {
	margin-left: 0.25em;
	width: 1em;
	height: 1em;
	display: inline-block;
	position: relative;
	top: 1px;
}
a[href$=".pdf"]::after {
	@extend .post-icon;
	content: url("/static/images/icons/isvg-pdf.svg");
}

.external-link::after {
	@extend .post-icon;
	content: url("/static/images/icons/iconmonstr-share-11.svg");
}

.icon-dollar {
	position: relative;
	&::before {
		content: "$";
		display: inline-block;
		font-size: rem(24px);
		font-weight: bold;
		// position: absolute;
		left: 0;
		top: 0;
	}
}

.page-content__body .im {
	position: relative;
  top: 1px;
  margin-right: 0.25em;
	&::before {
		font-size: 75%;
	}
}

.isvg.im {
	font: {
		family: inherit;
		size: inherit;
		weight: inherit;
	}
	&::before {
		font-family: 'iconmonstr-iconic-font';
	}
	&.im-picture-o::before {
		content: "\e055";
	}
}

.btn-w-icon {
	.isvg-bolt-2::before,
	.isvg-bolt-2--reverse::before {
		margin-top: 4px;
	}
}

/* --------------------- Custom inline SVG icons  --------------------- */
.isvg::before {
	min-width: 1em;
	min-height: 1em;
	display: inline-block;
}

.isvg-arrow--down::before {
	content: url("/static/images/icons/isvg-arrow--down.svg");
	margin-left: 0.325em;
	position: relative;
	top: 0.125em;
}

.isvg-search::before {
	content: url("/static/images/icons/isvg-search.svg");
}

.isvg-warning::before {
	content: url("/static/images/icons/isvg-warning--filled.svg");
	@include media-breakpoint-up(md) {
		content: url("/static/images/icons/isvg-warning-o2.svg");
	}
}

.isvg-warning-o::before {
	content: url("/static/images/icons/isvg-warning-o2.svg");
	@include media-breakpoint-up(lg) {
		height: rem(24px);
		width: rem(24px);
	}
}

.isvg-user::before {
	content: url("/static/images/icons/isvg-user.svg");
}

.isvg-user--duo::before {
	content: url("/static/images/icons/isvg-user--duo.svg");
}

.isvg-key--duo::before {
	content: url("/static/images/icons/isvg-key--duo.svg");
	min-height: 0.75em;
}

.isvg-printer--white::before {
	content: url("/static/images/icons/isvg-printer--white.svg");
}
.isvg-share--white::before {
	content: url("/static/images/icons/isvg-share--white.svg");
}

.isvg-bolt::before {
	content: url("/static/images/icons/isvg-bolt.svg");
}
.isvg-bolt-2::before {
	content: url("/static/images/icons/isvg-bolt-2.svg");
	margin-left: 4px;
}
.isvg-bolt-2--reverse::before {
	content: url("/static/images/icons/isvg-bolt-2--reverse.svg");
	margin-left: 4px;
}

.isvg-bolt-2--circled::before {
	content: url("/static/images/icons/isvg-bolt-2--circled.svg");
}

.isvg-money::before {
	content: url("/static/images/icons/isvg-money.svg");
}
.isvg-handcart::before {
	content: url("/static/images/icons/isvg-handcart.svg");
}

.isvg-facebook--reverse::before {
	content: url("/static/images/icons/isvg-facebook--reverse.svg");
}
.isvg-twitter--reverse::before {
	content: url("/static/images/icons/isvg-twitter--reverse.svg");
}
.isvg-youtube--reverse::before {
	content: url("/static/images/icons/isvg-youtube--reverse.svg");
}
.isvg-linkedin--reverse::before {
	content: url("/static/images/icons/isvg-indesign--reverse.svg");
}

.isvg-pdf::before {
	content: url("/static/images/icons/isvg-pdf.svg");
}

.isvg-close--white::before {
	content: url("/static/images/icons/isvg-x--white.svg");
}
.isvg-close--yellow::before {
	content: url("/static/images/icons/isvg-x--yellow.svg");
}
.isvg-close--red::before {
	content: url("/static/images/icons/isvg-close--red.svg");
}
.isvg-bolt--red::before {
	content: url("/static/images/icons/isvg-bolt--red.svg");
}
.isvg-flame--blue::before {
	content: url("/static/images/icons/isvg-flame--blue.svg");
}

.isvg-bolt--dark::before {
	content: url("/static/images/icons/isvg-bolt--dark.svg");
}

.isvg-electric-mobile::before {
	content: url("/static/images/icons/isvg-phone-electrified.svg");
}

.isvg-eyebolt::before {
	content: url("/static/images/icons/isvg-eyebolt.svg");
}

.isvg-lightpole::before {
	content: url("/static/images/icons/isvg-lightpole.svg");
}

.isvg-tree::before {
	content: url("/static/images/icons/isvg-tree.svg");
}

.isvg-mobile-tilted--turquoise::before {
	content: url("/static/images/icons/isvg-mobile-tilted--turquoise.svg");
}
.isvg-mobile-tilted--red::before {
	padding-left: 4px;
	content: url("/static/images/icons/isvg-mobile-tilted--red.svg");
}
.isvg-warning--duo::before {
	content: url("/static/images/icons/isvg-warning--duo.svg");
}

.isvg-calendar::before {
	content: url("/static/images/icons/isvg-calendar--duo.svg");
}

.toggle-submenu {
	@include caret;
	padding: $spacer / 2;
	transition: transform 0.25s;
	&::after {
		border-top-color: white;
		.submenu-is-visible & {
			transform: rotate(180deg);
		}
	}
}

.no-sidebar .toggle-submenu {
	display: none;
}

.page-sidebar {
	@extend .l-flex--column;
	font-size: 85%;
	z-index: 1;
	background: {
		image: linear-gradient(#f0f0f0, #f0f0f0 50%, white 90%);
		position: bottom;
		repeat: no-repeat;
	}
	@include media-breakpoint-up(md) {
		position: relative;
		padding: $spacer;
		width: 30%;
		&::before {
			content: "";
			width: 0;
			height: 0;
			display: block;
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			border: {
				style: solid;
				color: white transparent transparent transparent;
				width: $spacer * 3.5 0 0 $spacer * 3.5;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		padding: $spacer * 2 $spacer * 2 30vh;
		&::before {
			border-width: $spacer * 5.5 0 0 $spacer * 5.5;
		}
	}
	@include media-breakpoint-up(xl) {
		padding: $spacer * 2 $spacer * 4 30vh;
		background-image: linear-gradient(#f0f0f0, #f0f0f0 65%, transparent 90%);
	}
	@include media-breakpoint-down(sm) {
		max-height: 0;
		width: 100%;
		overflow: hidden;
		transition: max-height 0.5s;
		&.submenu-is-visible {
			max-height: 100vh;
		}
	}
	a {
		color: $primary;
	}
	&__title {
		@extend .heading--book;
		padding-bottom: $spacer * .75;
		padding-left: $spacer;
		border-bottom: solid 1px black;
		@include media-breakpoint-up(md) {
			padding-left: 0;
			margin-left: $spacer;
		}
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: {
				top: 0.25rem;
				bottom: 1.5rem;
			}
		}
		@include media-breakpoint-up(xl) {
			text-align: center;
		}
		@include media-breakpoint-down(md) {
			color: $primary;
			margin-top: $spacer;
			border-bottom-color: $primary;
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__nav {
		.active {
			font-weight: bold;
		}
		> ul > li {
			display: block;
			border-bottom: solid 1px rgba($primary, 0.3);
			padding-bottom: $spacer;
			margin: $spacer;
			@include media-breakpoint-up(lg) {
				margin: {
					left: $spacer / 2;
					right: $spacer / 2;
				}
			}

			&.active {
				@include pre-caret($accent);


				&:hover {
					@include media-breakpoint-up(md) {
						@include pre-caret($accent);
					}
				}
			}
		}
	}
	&__subnav {
		padding-left: $spacer;
		// border-top: solid 1px rgba($secondary, 0.3);
		@include media-breakpoint-up(lg) {
			margin-top: $spacer / 2;
		}
		li {
			margin-bottom: $spacer;
			display: block;
		}
		.active {
			@include pre-caret($medium);
			margin-left: -$spacer;
		}
	}
}

.sidebar-nav__container {
	@extend .stacked-nav;
}

.toggle-sidebar-subnav {
	float: right;
	&::after {
		border-top-color: $dark;
	}
	&.is-visible {
		transform: rotate(180deg);
	}
}

.page-sidebar__subnav {
	height: 0;
	min-height: 0;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-color: $dark;
	transition: all 0.25s;
	@include media-breakpoint-up(lg) {
		height: auto;
		background-color: transparent;
		border-left: solid 1px rgba($primary, 0.2);
		margin-left: $spacer / 2;
	}
	&.is-visible {
		height: auto;
		min-height: 100px;
		transition: all 0.25s;
		overflow: visible;
		margin-top: $spacer;
		&::before {
			content: "";
			display: block;
			position: absolute;
			z-index: 2;
			left: $spacer * 2;
			top: -$spacer / 2;
			border-bottom: solid $spacer / 2 $dark;
			border-left: solid $spacer / 2 transparent;
			border-right: solid $spacer / 2 transparent;
		}
	}
	li {
		margin-bottom: 0;
		padding: $spacer 0;
		@include media-breakpoint-up(lg) {
			padding: $spacer / 3 0;
		}
		&:not(:last-child) {
			border-bottom: solid 1px $midamerican-medgray;
			@include media-breakpoint-up(lg) {
				border-bottom: none;
				// border-color: rgba($primary, 0.15);
			}
		}
	}
	a {
		color: $light;
		@include media-breakpoint-up(lg) {
			color: $midamerican-medgray;
		}
	}
}

.sidebar--secondary {
	@include media-breakpoint-up(xl) {
		position: absolute;
		right: 0;
		top: 109px;
		// max-width: 280px;
		// max-width: 20%;
		width: 20%;
	}
	@include media-breakpoint-up(3xl) {
		// max-width: 320px;
		width: 320px;
	}
}

.sidebar__container {
	// background-color: rgb(240,240,240);
	background-color: rgba($secondary, 0.05);
	// background-color: rgba($primary, 0.1);
	padding: $spacer;
	margin-bottom: $spacer * 2;
	@include media-breakpoint-up(lg) {
		padding: $spacer*1.5;
	}
	strong {
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}
	h2,
	legend {
		color: $secondary;
		// color: desaturate($primary, 10%);
		border-color: $secondary;
		// border-color: $primary;
	}

	[class*="border-"] {
		border-color: rgba($secondary, 0.2) !important;
		// border-color: rgba($primary, 0.2) !important;
	}
}
body {
	background-color: $dark;
}

p {
	@include media-breakpoint-down(sm) {
		margin-bottom: $spacer;
	}
}

b, strong {
	font-weight: bold;
}

a {
	color: $secondary;
	text-decoration: underline;
	z-index: 1;
	&:hover {
		color: lighten($secondary, 10%);
		z-index: 2;
	}
}

a[href^="tel:"] {
	//color: inherit;
	display: inline-block;
	@include media-breakpoint-up(lg) {
		-webkit-appearance: none;
		-ms-appearance: none;
	}
}

a[data-toggle="tooltip"] {
	cursor: pointer;
}

nav a,
h1 a,
h2 a {
	text-decoration: none;
}

ul {
	padding-left: $spacer;
	@include media-breakpoint-up(sm) {
		padding-left: $spacer*2;
	}
}

ul ul {
	list-style-type: inherit;
}

form {
	max-width: 100%;
}

input[type="date"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
	-webkit-appearance: none;
	border: solid 2px $medium;
	padding: 0.25rem 0.5rem;
	border-radius: 0;
	&:focus,
	&:active {
		border-color: black;
		@media only screen and (-webkit-min-device-pixel-ratio: 0) {
			font-size: 16px;
		}
	}
	@include media-breakpoint-down(sm) {
		// min-width: 160px;
		background-color: white;
		padding: 0.75em 0.75em;
	}
}

input[type="tel"] {
	max-width: 16ch;
}
input[type="number"] {
	text-align: right;
	font-size: 1rem;
}
input[type="date"] {
	min-height: 2em;
	@include media-breakpoint-up(sm) {
		min-width: 200px;
	}
}
select {
	-webkit-appearance: none;
	border-radius: 0;
	background: {
		color: white;
		image: url("/static/images/icons/isvg-caret--down.svg");
		position: 93% 30%;
		repeat: no-repeat;
		size: 1rem;
	}
	padding-right: 2rem;
}
select::-ms-expand {
  display: none;
}

button[type="reset"],
button[type="submit"],
input[type="button"]  {
	@include button;
	@include button-material-hover;
	padding: 0.5em 1em;
}

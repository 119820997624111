.navbar-nav {
	@include media-breakpoint-up(lg) {
		@include pre-triangle($dark, 2.2em);
		&::before {
			-ms-transform: translateX(-34px);
		}
	}
	@include media-breakpoint-up(xl) {
		padding-right: 2em;
		@include pre-triangle($dark, 2.325em);
		&::before {
			-ms-transform: translateX(-41px);
		}
	}
	@include media-breakpoint-up(2xl) {
		@include pre-triangle($dark, 2.55em);
		&::before {
			-ms-transform: translateX(-41px);
		}
	}

}

.main-menu__container {
	@extend .justify-content-between;
	background-color: $dark;
	position: relative;
	@include media-breakpoint-up(lg) {
		width: 90%;
		max-width: rem(736px);
		// padding-right: 5vw;
	}
	@include media-breakpoint-up(2xl) {
		max-width: rem(800px);
	}
	@include media-breakpoint-up(3xl) {
		max-width: rem(960px);

	}
	.dropdown-menu {
		@extend .dropdown-menu-center;
		padding: $spacer/2 $spacer;
	}
	.nav-item:last-child > .dropdown-menu {
		@extend .dropdown-menu-right;
		left: auto;
		right: -65%;
		transform: translate(-12%, 0);
		min-width: 500%;
		max-width: 500%;

		&::before {
			left: auto;
			right: calc(10% - 1em);
			@include media-breakpoint-up(xl) {
				left: auto;
						right: calc(10% - .25em);
					}
				}
			}
		}

.dropdown-item {
	text-decoration: none;
	white-space: normal;
}

.dropdown-menu {
	> .dropdown__section {
		max-width: calc(50% - #{$spacer});
		flex-basis: calc(50% - #{$spacer});
		margin-bottom: $spacer;
		&:nth-child(2n+2) {
			margin-right: $spacer;
		}
	}
}
.main-menu {
	@extend .d-none, .d-lg-flex, .justify-content-end;
	padding-right: 0;

	.dropdown-menu {
		&.show {
			display: flex;
			flex-wrap: wrap;
		}
		width: auto;
		background: linear-gradient(white, lighten($light, 25%));
		color: $black;
		min-width: 34vw;
		max-width: 800px;
		@include drop-shadow(tall, 0.7);
		&::before {
			content: "";
			display: block;
			width: 1em;
			height: 1em;
			background-color: white;
			position: absolute;
			top: calc(-0.5em - 1px);
			left: calc(50% - 0.75em);
			transform: rotate(45deg);
			border: {
				left: 1px solid rgba(0,0,0,0.25);
				top: 1px solid rgba(0,0,0,0.25);
			}
		}
		a {
			display: block;
		}
	}
	.dropdown-menu-center {
	  left: 50%;
	  right: auto;
	  transform: translate(-50%, 0);
	}
	.dropdown-item {
		padding: 0;
		text-indent: -1em;
		padding-left: 1em;
		color: $secondary;
		&:hover {
			background-color: transparent;
			text-decoration: underline;
		}
	}
	.dropdown__heading {
		font-weight: bold;
		// border-bottom: solid 2px $lighter;
		color: $dark;
	}

	.dropdown-arrow[aria-expanded="true"]::after,
	.dropdown-toggle[aria-expanded="true"]::after {
		transform: rotate(180deg);
	}

	.navbar {
		padding: 0;
	}
	.nav-item {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.nav-link {
		height: 100%;
		color: white;
		font: {
			size: rem(14px);
			weight: 600;
		}
		@include media-breakpoint-up(xl) {
			font-size: rem(16px);
		}
		@include media-breakpoint-up(2xl) {
			font-size: rem(18px);
			padding: {
				top: 0.5rem;
				bottom: 0.5rem;
			}
		}
		&:hover,
		&:active {
			color: white;
			outline: none;
			@include media-breakpoint-up(lg) {
				background-color: lighten($primary, 20%);
			}
		}
	}
}

.dropdown-contained .dropdown-menu {
	transform: translate3d(0, 45px, 0px) !important;
}

.dropdown__title {
	@extend .heading--reset;
	flex: 1 0 100%;
	width: 100%;
	color: $accent;
	// border-bottom: solid 2px $light;
	padding-bottom: $spacer / 3;
	margin-bottom: $spacer / 1.5;
	text-align: center;
}
.main-menu {
	.dropdown__three-col {
			width: 65vw;
			min-width: rem(480px);
			padding: {
				left: inherit;
				right: inherit;
			}
			.nav-link {
			font-size: rem(12px);
			@include media-breakpoint-up(xl) {
				font-size: rem(13.5px);
			}
			@include media-breakpoint-up(2xl) {
				font-size: rem(15px);
			}
			@include media-breakpoint-up(3xl) {
				font-size: rem(16px);
			}
		}
		.col {
			padding: 0.25em 1em;
		}
		.dropdown__section {
			> a {
				line-height: 1.75;
			}
			.dropdown__subsection {
				color: $secondary;
				text-indent: 0;
				padding-left: 1.225em;
				list-style: square;
				font-weight: 400;
			}
		}
	}
}
/* -------------------------------------------------- \
 *																										*
 *								Custom Variables:										*
 *																										*
\* -------------------------------------------------- */

$global-font-smoothing: true !default;
$html-font-size: 18px;
$body-font-size: 18px;
$em-base: 18;
$rem-with-px-fallback: false !default;
$body-line-height: 1.325;
$base-font-size: 18px;

$shadows: (
  base: (0 3px 12px -6px),
  shallow: (0 2px 6px -6px),
  tall: (0 6px 16px -8px)
);

$cubic: cubic-bezier(0,0.7,0.7,1);



/* -------------------------------------------------- \
 *																										*
 *				Twitter Bootstrap variable overrides: 			*
 *																										*
\* -------------------------------------------------- */

// Colors:

$midamerican-red: #e60d2e;
$midamerican-black: black;
$midamerican-darkgray: #333333;
$midamerican-medgray: #707070;
$midamerican-lightgray: #999999;
$midamerican-turquoise: #00687f;
$midamerican-yellow: #ffe924;
$midamerican-orange: #ffa619;
$midamerican-yelloworange: #EBAA1F;
$green: #00761B;

$primary:       $midamerican-darkgray;
$accent:				$midamerican-red;
$secondary:     $midamerican-turquoise;
$success:       $green;
$info:          $midamerican-orange;
$warning:       $midamerican-yelloworange;
$danger:        $midamerican-red;
$medium:        $midamerican-medgray;
$light:         $midamerican-lightgray;
$lighter:       #b4b4b4;
$lightest:      #E1E1E1;
$dark:          $midamerican-darkgray;
$black:         $midamerican-black;

$body-bg: white;
$body-color: $primary;

$theme-colors: (
	"primary": 		$midamerican-darkgray,
	"accent": 		$midamerican-red,
	"secondary": 	$midamerican-turquoise,
	"info": 			$midamerican-orange,
	"danger": 		$midamerican-red,
	"light": 			$midamerican-lightgray,
	"dark": 			$midamerican-darkgray,
	"black": 			$midamerican-black,
);

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1366px,
  3xl: 1440px,
  4xl: 1920px
);

$container-max-widths: (
  sm: 420px,
  md: 720px,
  lg: 960px,
  xl: 1120px,
 2xl: 1280px,
 3xl: 1360px
);

// --------------------- Shapes: --------------------- //

$border-radius:   0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$enable-shadows: true;
$btn-box-shadow: 0 3px 12px -8px rgba(black, 20%);

$hamburger-layer-width: 34px;

// --------------------- Text: --------------------- //

$font-size-base: 1rem;

$font-family-default: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

$font-family-headings: "futura", $font-family-default;

$enable-responsive-font-sizes: true;

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
.stacked-nav {
	list-style: none;
	padding-left: 0;
	line-height: 1.2;
}
.nav-tabs {
	border-bottom-color: $primary;
	.nav-link,
	.nav-link.active,
	.nav-item.show .nav-link {
		color: $secondary;
		text-decoration: none;
		font-weight: normal;
		&:hover {
			// text-decoration: underline;
			color: lighten($secondary, 10%);
		}
	}

	.nav-link.active,
	.nav-item.show .nav-link {
		border-color: $primary $primary white;
	}
	.nav-link:hover {
		$link-color: lighten($secondary, 10%);
		border-color: $link-color $link-color white;
	}
}

.icon-list {
	list-style: none;
	padding-left: 0;

	li {
		position: relative;
		display: block;
		padding-left: $spacer*1.5;
		&::before {
			position: absolute;
			left: 0;
			top: 15%;
			height: 18px;
			width: 18px;
			transform: translateY(-15%);
		}
		a::after {
			content: none;
		}
	}
}